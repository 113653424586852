import type {
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
} from "next";
import { getServerSession } from "next-auth/next";
import { getCsrfToken, getProviders, signIn } from "next-auth/react";
import { ROUTES } from "~/utils/route";

import { useRouter } from "next/router";
import { ClearLayout } from "~/components/ClearLayout";
import { authOptions } from "~/server/auth";
import type { PageLayoutParams } from "../_app";

const signin_errors = {
  default: "Unable to sign in.",
  AccessDenied: "You are not authorized to access this page.",
  Signin: "Try signing in with a different account.",
  OAuthSignin: "Try signing in with a different account.",
  OAuthCallbackError: "Try signing in with a different account.",
  OAuthCreateAccount: "Try signing in with a different account.",
  EmailCreateAccount: "Try signing in with a different account.",
  Callback: "Try signing in with a different account.",
  OAuthAccountNotLinked:
    "To confirm your identity, sign in with the same account you used originally.",
  EmailSignin: "The e-mail could not be sent.",
  CredentialsSignin:
    "Sign in failed. Check the details you provided are correct.",
  SessionRequired: "Please sign in to access this page.",
};

export default function SignInPage({
  error,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const router = useRouter();

  const email_submit_handler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = new FormData(e.currentTarget).get("email") as string;
    void signIn("email", { redirect: false, email })
      .then((res) => {
        if (!res || res?.error || !res?.ok) {
          router.push({
            query: { error: res?.error ?? "default" },
          });
        }
        if (res?.url) {
          router.push(res.url);
        }
      })
      .catch((error) => {
        console.error("[signin] error", error);
        router.push({ query: { error: "default" } });
      });
  };

  return (
    <>
      <div className="flex min-h-screen flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-[96px] w-auto mx-auto"
                src="/assets/logo_mascot.png"
                alt="Logo"
                width={96}
                height={96}
              />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign in to your account
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Don't have an account?{" "}
                <a
                  href={ROUTES.email.support}
                  className="font-semibold text-yellow-600 hover:text-yellow-500"
                >
                  Contact us
                </a>
              </p>
            </div>

            <div className="mt-10">
              <div>
                {error ? (
                  <div className="rounded-md bg-red-50 p-4 mb-4">
                    <div className="flex">
                      <h3 className="text-sm font-medium text-red-800">
                        {error}
                      </h3>
                    </div>
                  </div>
                ) : null}
                <form
                  method="POST"
                  className="space-y-6"
                  onSubmit={email_submit_handler}
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="pt-8">
                    <button
                      type="submit"
                      className=" flex w-full justify-center rounded-md bg-yellow-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                    >
                      Sign in
                    </button>
                    <p className="mt-2 text-center text-sm text-gray-500">
                      You will receive an email with a link to sign in.
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          {/* Create gradient background */}
          <div
            className="absolute inset-0 h-full w-full object-cover bg-gradient-to-tr from-sky-50 via-yellow-100 to-slate-100"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80')",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </div>
      </div>
    </>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const session = await getServerSession(context.req, context.res, authOptions);

  // If the user is already logged in, redirect.
  // Note: Make sure not to redirect to the same page
  // To avoid an infinite loop!
  if (session) {
    return { redirect: { destination: "/" } };
  }

  const [providers, csrf_token] = await Promise.all([
    getProviders(),
    getCsrfToken({ req: context.req }),
  ]);

  const error_code = context.query.error as keyof typeof signin_errors | null;
  const error = error_code
    ? signin_errors[error_code] ?? signin_errors.default
    : null;

  return {
    props: { providers: providers ?? [], error, csrf_token },
  };
}

(SignInPage as PageLayoutParams).layout = {
  component: ClearLayout,
};
